import request from "@/services/request";
import store from "@/store";

async function getForm(nameForm = '') {
  try {
    let params = '';
    if (store.getters['auth/getFakeUser'])
      params = `?orgId=${store.getters['keycloak/getOrgId']}`
    const res = await request({
      endpoint: `/api/school/${nameForm}${params ? params : ''}`,
      method: 'get',
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error('error getFormOne');
    }
  } catch (e) {
    await store.dispatch('notifications/addNotification', {
      notificationStatus: 'error',
      notificationMessage: 'ошибка получения данных формы' + ' ' + e,
      timeout: 3000
    }, {root: true});
    console.error('error getFormOne' + e)
    throw new Error('error getFormOne' + e)
  }
}

async function deleteForm(nameForm = '', id) {
  try {
    const res = await request({
      endpoint: `/api/school/${nameForm}/${id}`,
      method: 'delete',
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error('error getFormOne');
    }
  } catch (e) {
    await store.dispatch('notifications/addNotification', {
      notificationStatus: 'error',
      notificationMessage: 'ошибка удаления ' + ' ' + e,
      timeout: 3000
    }, {root: true});
    console.error('error getFormOne' + e)
    throw new Error('error getFormOne' + e)
  }
}

async function setForm(nameForm = '', payload) {
  try {
    const res = await request({
      endpoint: `/api/school/${nameForm}`,
      method: 'post',
      body: payload
    });
    if (res.status === 200) {
      return res.data;
    } else if (res.status === 208) {
      throw new Error('такая строчка уже существует');
    } else {
      throw new Error('error setFormOne');
    }
  } catch (e) {
    await store.dispatch('notifications/addNotification', {
      notificationStatus: 'error',
      notificationMessage: 'ошибка получения данных формы 1 ' + ' ' + e,
      timeout: 3000
    }, {root: true});
    console.error('error setFormOne' + e)
    throw new Error('error setFormOne' + e)
  }
}

export {getForm, setForm, deleteForm}
